* {
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
#root { 
  display: flex; 
  flex-direction: column; 
  flex: 1;
  background-color: #161F31;
  width: '100%'; 
  height: '100%'; 
}

#App { display: flex; flex-direction: column; }
.home {width: '100%'; height: '100%'; background-color: #161F31;}

.collectionContainer {
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 2%;
}

.roadmapContainer {
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 2%;
}

.faqContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2%;

}

.aboutContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2%;

}

.faqQuestions {
  padding: 0% 4%;
}

.collectionContainer{
  display: flex;
  flex-direction: row;
}

.walletButton {
  white-space: pre-wrap;
}

.collectionFilter{
  display: inline-flex;
  flex-direction: column;
  flex: 0.25;
  padding: 1%;
  background-color: #FFFFFF;
}

.collectionView{
  display: flex;
  background-color: #48158B;
  flex: 1;
  justify-content: space-around;
}

.cardTitle {
  margin-bottom: 30px;
}
.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card {
  /* width: 300px;
  height: 300px;
  transition: 0.3s;
  box-shadow: 0 8px 40px -12px rgba(0,0,0,0.3); */
}
.cardMedia {
  padding-top: 80%;
  height: 100%;
}

.cardHeading {
  font-weight: bold;
}
.cardSubHeading {
  line-height: 1.8;
}
.cardAvatar {
  display: inline-block;
  border: 2px solid white;
}
.cardDetails {
  height: 50%;
  font-weight: bold;
  display: none;
}
.cardDetails2 {
  height: 20%
}

.cardRoadmap {
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 2%;
}

.MuiCardMedia-root{
  height: 100%;
}

.progress {
  position: absolute; 
  left: 50%; top: 50%; 
  -webkit-transform: translate(-50%, -50%); 
  transform: translate(-50%, -50%);
}

.pagination-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}